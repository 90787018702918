<template>
    <div>
        <loading-spinner v-if="isLoading" />
        <div class="material-header">
            <h2 class="material-header__title">
                Редактирование документа
            </h2>
        </div>
        <ValidationObserver tag="form" class="form-propose js-without-double-block" @submit.prevent="onSubmit"
                            ref="observer" method="post" enctype="multipart/form-data">
            <div class="material-content">
                <!--Вкладка - Материал-->
                <ValidationProvider rules="required|max_length:255" v-slot="provider">
                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_name">Название документа *</label>
                            <input v-model="form.name" type="text" name="name" maxlength="255" id="id_name"
                                   class="form-control">
                            <div id="id_name-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>
                <ValidationProvider tag="fragment" vid="rubric" :rules="current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin ? 'required' : null" v-slot="provider">
                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_rubrics">
                                Рубрика<span v-if="current_user.is_editor_rubric_docs && !current_user.is_editor_document && !current_user.is_admin"> *</span>
                            </label>
                            <v-select2 class="form-material"
                                       id="id_rubrics"
                                       v-model="form.rubric"
                                       :reduce="name => name.id"
                                       :options="listRubrics" label="name"
                                       :clearable="true" :searchable="true">
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                            <div id="id_rubrics-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="id_materials">
                            Материалы
                        </label>
                        <v-select2 class="form-material" id="id_materials"
                                   v-model="form.material"
                                   :reduce="title => title.id"
                                   :options="listMaterials" label="title"
                                   :clearable="true" :searchable="true">
                            <span slot="no-options">Ничего не найдено</span>
                        </v-select2>
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-material floating open">
                        <label for="id_type">
                            Тип документа
                        </label>
                        <v-select2 class="form-material"
                                   id="id_type"
                                   v-model="form.type" :reduce="label => label.value" :options="typeOption"
                                   :clearable="false" :searchable="false">
                        </v-select2>
                    </div>
                </div>

                <ValidationProvider :rules="form.type === 1 ? 'required|max_length:255' : null" v-slot="provider"  vid="url">
                    <div class="form-group" v-show="form.type === 1"
                         v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_url">
                                Внешняя ссылка на документ
                            </label>
                            <input v-model="form.url" type="text" name="url" maxlength="200" id="id_url"
                                   class="form-control">
                            <div id="id_url-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <ValidationProvider :rules="form.type === 2 && form.file && form.file.length === 0 ? 'required' : null" v-slot="provider">
                    <div class="form-group" v-show="form.type === 2"
                         v-bind:class="{'is-invalid': provider.errors.length > 0}">
                        <div class="form-material floating open">
                            <label for="id_file">Файл</label>
                            <span v-show="!new_file">{{file_name}} <br> </span>
                            <input v-on:change="onFileChange" type="file" name="file" id="id_file" ref="docFile">
                            <div id="id_file-error" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </div>
                </ValidationProvider>

                <div class="form-material floating open">
                    <span v-show="!infoPermissionText">
                        Документ доступен всем
                    </span>
                    <span v-show="infoPermissionText">
                        <span class="material__access-txt">{{ infoPermissionText }}</span>
                    </span>
                    <a href="javascript:void(0)" class="material__access"
                       @click="changePermission">
                        <svg width="15" height="15" viewBox="0 0 15 15"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z"></path>
                        </svg>
                        Настроить доступ
                    </a>
                </div>

                <div class="material-footer">
                    <router-link
                        tag="div"
                        :to="`/documents`"
                        class="material-footer__reset">
                        Отмена
                    </router-link>
                    <button class="material-footer__add" type="submit">
                        Сохранить
                    </button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";
    import axios from 'axios';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';

    export default {
        name: "UpdateDocument",
        components: { LoadingSpinner },
        props: {
            document_id: {
                type: String
            }
        },
        data() {
            return {
                infoPermissionText: '',
                typeOption: [
                    {
                        value: 1,
                        label: 'Внешняя ссылка'
                    },
                    {
                        value: 2,
                        label: 'Выбрать документ'
                    }
                ],
                listRubrics: [],
                listMaterials: [],
                file_name: '',
                new_file: false,
                form: {
                    name: '',
                    rubric: null,
                    material: '',
                    type: 1,
                    url: '',
                    file: null,
                },
                isLoading: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user',
            ])
        },
       async created() {
            await this.permissionDocument();
            await session
                .get('/api/v1/material/all_list/')
                .then(response => {
                    this.listMaterials = response.data;
                })
                .catch(() => {
                });
            await session
                .get('/api/v1/document/rubrics/')
                .then(response => {
                    this.listRubrics = response.data;
                })
                .catch(() => {
                });
            await session
                .get(`/api/v1/document/${this.document_id}/`)
                .then(response => {
                    const data = response.data;
                    this.form = data;
                    this.document = Object.assign({}, data);
                    this.file_name = data.file_name;
                    this.infoPermissionText = data['permission_text'];
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.$router.push('/404');
                    }
                });
        },
        methods: {
            changePermission() {
                let checkbox = this.form.rubric? 'Как у родительской рубрики' : 'Для всей компании';
                const ModalPermissionUpdate = () => import(`../../permission/ModalPermissionUpdate`);
                this.$modal.show(ModalPermissionUpdate, {
                    title: 'Настройка доступа к документу',
                    text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному документу',
                    checkbox: checkbox,
                    rubricID: this.form.rubric,
                    isMainRubric: this.document.rubric ? this.form.rubric === this.document.rubric.id : true,
                    permissionType: 'document',
                    permissionTypeParent: 'rubric_document',
                    permissionObjectID: +this.document_id
                }, {
                    name: 'ModalPermissionUpdate',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                }, {
                    'before-close': this.updateTextPermission
                })
            },
            updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data;
                    this.infoPermissionText = data.info_text;
                }
            },
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.form.file = files[0];
                this.file_name = this.form.file.name;
                this.new_file = true;
            },
            async onSubmit(e) {
                e.preventDefault();
                let isValidModal = await this.$refs.observer.validate();
                if (isValidModal) {
                    let progress_bar = this.$Progress;
                    let url = `/api/v1/document/${this.document_id}/`;
                    let config = {
                        onUploadProgress(progressEvent) {
                            let percentCompleted = Math.round((progressEvent.loaded * 100) /
                                progressEvent.total);
                            progress_bar.set(percentCompleted);
                            return percentCompleted;
                        },
                    };
                    this.$Progress.start();
                    let data = this.form;

                    if (this.form.file && typeof (this.form.file) !== 'string') {
                        config['headers'] = {
                            'Content-Type': 'multipart/form-data'
                        };
                        // Добавляем данные из объекта в formData для отправки на сервер
                        let formData = new FormData();
                        this.form.rubric = this.form.rubric ? this.form.rubric : '';
                        this.form.material = this.form.material ? this.form.material : '';
                        this.form.url = this.form.url ? this.form.url : '';
                        for (let [key, value] of Object.entries(this.form)) {
                            if (key === 'file') {
                            // файл заливается на фронте, а не на бэке, нет необходимости его отправлять
                                continue
                            }
                            else if (key === 'permissionData') {
                                formData.append('permissionData', JSON.stringify(value));
                            } else {
                                formData.append(key, value);
                            }
                        }
                        let file_name = this.form.file.name.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '');
                        formData.set('file_name', file_name);
                        formData.set('file_size', this.form.file.size);
                        data = formData;
                    } else {
                        this.form.file = null;
                    }
                    await session
                        .put(url, data, config)
                        .then(async response => {
                            if ('upload_link' in response.data) {
                                await this.uploadFileByLink(response.data.upload_link)
                            }
                            this.$Progress.finish();
                            this.$router.push('/documents/');
                        })
                        .catch((error) => {
                            this.$Progress.finish();
                            if (error.response) {
                                this.$refs.observer.setErrors(error.response.data);
                            }
                        })
                }
            },
            async uploadFileByLink(uploadLink) {
                try {
                    this.isLoading = true
                    await axios.put(uploadLink, this.form.file, {})
                    this.isLoading = false
                } catch (e) {
                    this.isLoading = false
                    console.log('error', e)
                }
            },
            async permissionDocument() {
                const params = {
                    permission_type: 'document',
                    permission_object_id: this.document_id
                }
                try {
                    const response = await session.get('/api/v1/permission/retrieve_vue/', { params })
                    if (response.data) {
                        this.form.permissionData = response.data;
                        this.form.permissionData.note.parentRight = true;
                        this.infoPermissionText = response.data.info_text;
                        this.permissionDataCache = null;
                    }
                } catch (e) {
                    console.log('error', e.message)
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .material-footer__reset {
        cursor: pointer;
    }
</style>
